.cm-input-container {
  width: 95%;
  margin: auto;
  margin-top: 0;
  margin-bottom: 2rem;
  border: 1px solid rgba(221, 221, 221, 1);
  display: flex;
  border-radius: 0.5rem;
  background-color: white;
  height: 140px;
  min-height: 140px;
}

.cm-input-block {
  width: 100%;
  height: 140px;
  min-height: 140px;
}

.cm-form {
  height: 140px;
  min-height: 140px;
}

.cm-input-box {
  width: 100%;
  display: flex;
  justify-content: end;
}

.cm-input-top {
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  height: 25px;
  background-color: rgb(211, 211, 211, 0.1);
}

.cm-input-top-box {
  padding-right: 1.5rem;
  color: rgb(211, 211, 211, 1);
}

.cm-input-box {
  padding: 0.5rem 1rem;
  height: 80px;
}

.cm-input {
  width: 100%;
  border: none;
  outline: none;
  resize: none;
  font-family: "Noto Sans";
  font-size: 0.8rem;
  line-height: 1rem;
}

.cm-input-bottom {
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 30px;
}

.cm-submit-box {
  width: fit-content;
  padding: 0.5rem 0.5rem;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cm-submit-button,
.cm-cancel-button {
  padding: 0.5rem 0.8rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  font-size: 0.8rem;
  text-align: center;
}

.cm-cancel-button {
  border: 1px solid rgba(221, 221, 221, 1);
  background-color: white;
  margin-right: 0.5rem;
}

.cm-submit-button-highlight-false {
  color: rgb(221, 221, 221);
  background-color: white;
  border: none;
}
.cm-submit-button-highlight-true {
  color: #ffffff;
  background-color: #2bac76;
  border: none;
}
