.dm-history {
  background-color: rgb(248, 248, 248);
  height: 100%;
}

.dm-history-scroll {
  /* position: absolute; */
  /* bottom: 0; */
  /* left: 0; */
  outline: none;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.dm-sameblock-date {
  font-size: 14px;
  font-weight: 600;
  margin: 0 16px;
  padding: 24px 0 12px 16px;
}

.dm-sameblock-group {
  align-items: flex-start;
  margin: 0 16px;
  padding: 12px 16px;
  border-left: 1px solid rgb(186, 184, 184);
  border-right: 1px solid rgb(186, 184, 184);
  border-top: 1px solid rgb(186, 184, 184);
  text-decoration: none;
}

.top-left-right {
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
}

.bottom-left-right {
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  border-bottom: 1px solid rgb(186, 184, 184);
}

.dm-user-icon {
  margin: 4px 10px 0 0;
  cursor: pointer;
}

.dm-user-icon-img {
  width: 36px;
  height: 36px;
  border-radius: 4px;
}

.dm-username-msg {
  font-size: 15px;
  flex: 1;
}

.dm-username {
  align-items: center;
  cursor: pointer;
  font-weight: 600;
  color: #1d1c1d;
}

.dm-msg {
  max-height: 44px;
  min-height: 22px;
  overflow: hidden;
  cursor: pointer;
  color: #1d1c1db3;
  font-weight: 400;
}

/* .dm-msg-detail {
  
  } */

.dm-time {
  font-size: 14px;
  color: #1d1c1db3;
  font-weight: 400;
}
